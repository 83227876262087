class helpers {
     changeCurrency(money) {
        if(!money) return ''
        const format = money.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = convert.join('.').split('').reverse().join('')
        return rupiah
    }
    copyValue(item, evt) {
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(item, evt );
          return;
        }
        const that = this
         navigator.clipboard.writeText(item).then(
          function () {
            // eslint-disable-next-line no-undef
            that.createElementDestroy(evt, 'Copy to clipboard')
            //   console.log("Async: Copying to clipboard was successful!");
            // success copy clipboard
          },
          function () {
            //   console.error("Async: Could not copy text: ", err);
          }
        );
        //   var tooltip = document.getElementById(id);
        //   tooltip.innerHTML = "Copy Success";
      }

      createElementDestroy(evt , text){
        if(document.getElementById('copy-element')){
            document.getElementById('copy-element').remove()
            clearTimeout(this.timeOutCopy)
        }
        const element = document.createElement('span')
        element.classList.add('absolute-copy')
        element.setAttribute('id', 'copy-element')
        element.innerHTML = text
        evt.target.appendChild(element)
        this.timeOutCopy = setTimeout(()=> {
             document.getElementById('copy-element').remove()
        }, 3000)
    }

      fallbackCopyTextToClipboard(text,evt) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textArea);
        this.createElementDestroy(evt, 'Copy')
      }

      setTimer(time) {
        if (time >= 3600) {
          const hours = parseInt(time / 3600);
          const minutes = parseInt((time - hours * 3600) / 60);
          const seconds = parseInt((time - hours * 3600) % 60);
          return `${hours.toString().padStart(2, "0")} : ${minutes
            .toString()
            .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
        } else if (time >= 60) {
          return `${parseInt(time / 60)
            .toString()
            .padStart(2, "0")} : ${(time % 60).toString().padStart(2, "0")}`;
        } else {
          return `00 : ${time.toString().padStart(2, "0")}`;
        }
      }

}

// eslint-disable-next-line no-class-assign
export default (helpers = new helpers());